html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

body {
  position: relative;
  font-family: "Graphik Web", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: var(--fg);
  background: var(--bg);
  line-height: 1.4;
}

.body {
  min-height: 100vh;
}
