@supports (grid-template-rows: masonry) {
  .articles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: masonry;
  }

  .articles__article.cell {
    /* Reset the effect of the cell size classes */
    grid-column-end: span 1 !important;
  }
}

.articles {
  padding: 1rem;
  gap: 1rem;
}

.articles--featured {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.articles__article {
  position: relative;
  padding: 0;
}

.articles__image {
  margin: 0.5rem;
  position: relative;
  z-index: 1;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.articles__body {
  padding: 2rem 2rem 1.5rem;
  overflow-wrap: break-word;
}

.articles__image + .articles__body {
  background: var(--bg);
  color: var(--fg);
  margin: -2rem 0.5rem 0.5rem;
}

.articles__article--featured .articles__body {
  color: var(--white);
  background: transparent;
}

.articles__title {
  font-size: var(--fs-large);
  margin: 0.25rem 0 1.5rem;
  line-height: 1.1;
}

.articles__interesting {
  margin: 1rem -1rem 0 0;
  display: block;
  text-align: right;

  :is(svg) {
    --size: 1.125em;
    margin-left: 0.5rem;
    vertical-align: text-bottom;
  }
}

@media (--media-sm-only) {
  .flips--article {
    height: 22rem;

    .flips__part--text {
      left: 1rem !important;
    }
  }
}
