:root {
  --menu-width: 4rem;
}

.menu {
  width: var(--menu-width);
  display: flex;
  flex-flow: column nowrap;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: var(--bg);
  border-right: var(--border);
  overflow: hidden;

  z-index: var(--z-menu);
}

.menu ~ .body {
  margin-left: var(--menu-width);
}

.menu__toggle {
  display: block;
  width: 1.75rem;
  height: 1.5rem;
  margin: 1rem;
  cursor: pointer;
}

#sl-toggle {
  pointer-events: none;
}

.menu__close {
  display: none;
}

#expand-menu {
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

#expand-menu:checked {
  + .menu {
    width: 100vw;
    z-index: var(--z-top);
    transition: width 0.3s ease-out, height 0.3s ease-out;

    .menu__close {
      display: block;
    }
    .menu__open {
      display: none;
    }
    .menu__menu {
      opacity: 1;
      transition: opacity 0.2s;
      transition-delay: 0.3s;
    }
  }
}

@media (--media-md) {
  .menu__logo {
    display: block;

    :is(svg) {
      transform: rotate(270deg);
      width: 7rem;
      height: 7rem;
      transform-origin: center 73%;
      margin-top: -18px;
    }
  }
}

.menu__menu {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;

  opacity: 0;

  position: absolute;
  left: calc(var(--menu-width) + 20%);
  right: var(--menu-width);
  top: 10vh;
  bottom: 15vh;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;

  :is(a) {
    color: inherit;
    font-size: 2rem;
    text-decoration: none;
    white-space: nowrap;
  }

  :is(a:hover, a:focus-visible, .active) {
    color: inherit;
    font-weight: bold;
  }
}

.menu__social {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;
  width: var(--menu-width);
  margin: auto 0 1rem;

  :is(a) {
    color: inherit;
    text-decoration: none;
    display: grid;
    place-items: center;
  }

  .icon {
    --size: 2em;
  }
}

@media (--media-sm-only) {
  .menu {
    top: 0;
    right: 0;
    left: 0;
    bottom: auto;
    height: var(--menu-width);
    width: auto;
    border: none;
    border-bottom: var(--border);
  }

  .menu ~ .body {
    margin-left: 0;
    margin-top: var(--menu-width);
  }

  .menu__logo {
    position: absolute;
    left: 5rem;
    right: 5rem;
    top: 1rem;
    bottom: 1rem;

    svg {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .menu__menu {
    left: 3rem;
    right: 3rem;
  }

  #expand-menu:not(:checked) + .menu .menu__social {
    display: none;
  }

  #expand-menu:checked {
    + .menu {
      height: 95vh;
      /* biome-ignore lint/nursery/noDuplicateProperties: dvh may not be supported everywhere */
      height: 100dvh;

      .menu__logo {
        display: none;
      }

      .menu__close {
        display: block;
      }
      .menu__open {
        display: none;
      }
    }
  }
}

@media (--media-lg) {
  .menu__menu :is(a) {
    font-size: 3rem;
  }
}
