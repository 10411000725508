.header__title {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: var(--border);
  hyphens: auto;

  @media (--media-sm-only) {
    flex-wrap: wrap;
  }

  :is(h1) {
    position: relative;
    top: -0.1em;
    margin: 1rem;
    white-space: nowrap;

    :is(a) {
      color: inherit;
      text-decoration: none;
    }
  }

  .filter {
    margin-right: auto;
  }
}
