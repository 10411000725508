.sidebar {
  display: flex;
  justify-content: center;
}

.sidebar__menu {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;

  :is(a) {
    color: inherit;
    text-decoration: none;
  }

  :is(a:hover, a:focus-visible, .active) {
    text-decoration: underline;
  }

  /* eye-catcher (notification) when a job is available */
  :first-child {
    position: relative;

    &[data-jobs]::after {
      content: attr(data-jobs);
      position: absolute;
      right: -0.5em;
      top: -0.6em;
      background-color: red;
      color: var(--white);
      width: 1.12em;
      height: 1.12em;
      line-height: 1em;
      text-align: center;
      border-radius: 50%;
      font-weight: 700;
    }
  }
  /* End eye-catcher */
}

@media (--media-sm-only) {
  .sidebar {
    border-top: var(--border);
  }
}

@media (--media-md) {
  .sidebar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: var(--menu-width);
    border: none;
    border-left: var(--border);
    z-index: var(--z-menu);
    flex-flow: column nowrap;
    justify-content: flex-end;
  }

  .body {
    margin-right: var(--menu-width);
  }

  .sidebar__menu {
    transform: rotate(270deg);
    backface-visibility: hidden; /* Hack to make fonts look nice... */
    transform-origin: center;
    justify-content: flex-start;
    padding-left: 1.5rem;
  }
}
