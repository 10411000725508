.projects__body {
  padding: 1rem 0 2rem;
}

.projects__category {
  background: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  strong {
    white-space: nowrap;
  }
}

html[data-theme="dark"] {
  --gradient: #8ca8de;
}

.projects__title {
  margin: 0.5rem 0 1rem;
}
