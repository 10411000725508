.directive--title-text {
  margin-block: var(--p-lg);

  @media (--media-lg) {
    .cell:nth-of-type(1) {
      .prose {
        margin-right: unset;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        text-align: right;
        line-height: 1.4;
      }
    }
  }

  .cell:nth-of-type(2) {
    .prose {
      margin-left: unset;
    }
    /* Like .lead */
    p {
      font-size: 1.5375rem;
      line-height: 1.4;
    }
  }
}
