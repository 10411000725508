:root {
  --radius: 1.5rem;
}

.grid--borders {
  gap: 1px;

  > .cell {
    outline: var(--border);
  }
}

.cell__gradient {
  color: white;
  position: relative;
}

.cell__gradient::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--gradient);
  z-index: -1;
}

@keyframes gridReveal {
  to {
    opacity: 1;
  }
}

.js {
  .grid--reveal > .cell {
    opacity: 0;
    animation: 0.5s ease-in calc(var(--delay) * 0.03s) 1 forwards gridReveal;
  }

  @for $i from 1 to 12 {
    .grid--reveal > .cell:nth-child($i) {
      --delay: $i;
    }
  }

  .grid--reveal > .cell:nth-child(n + 12) {
    --delay: 13;
  }
}

@media (--media-lg) {
  .cell.indented > * {
    margin-left: clamp(1rem, 2vw, var(--p-lg));
  }
}
