.prose {
  margin-left: auto;
  margin-right: auto;
  max-width: 65ch;

  h1,
  h2 {
    overflow-wrap: break-word;
    hyphens: auto;
  }

  h3,
  h4 {
    font-size: inherit;

    + * {
      margin-top: 0;
    }
  }
}

.prose--tube {
  margin-top: var(--p-lg);
  margin-bottom: calc(var(--p-lg) + var(--p));
}

.tube--cropped .prose--tube {
  margin-bottom: 0;
}

.prose a:not([class]) {
  color: #2967e1;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }

  html[data-theme="dark"] & {
    color: #8ca8de;
  }
}

.figure {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  & img {
    margin: 0 auto;
    flex: 1 1 100%;
  }
}

.figcaption {
  margin-top: 1em;
}

.copyright {
  font-size: 10px;
  opacity: 0.7;
}

.large-prose {
  font-size: 1.25em;
  line-height: 1.5;

  :is(h2) {
    font-size: 1.25em;
  }
}

.video {
  max-width: 100%;
}

.lead {
  font-size: 1.5375rem;
}

.angles.large-prose {
  display: flex;
  align-items: center;
}
