.newsletter {
  text-align: center;
  padding: 2rem;

  :is(h2) {
    font-size: var(--fs-large);
  }

  :is(h2, p) {
    margin-left: max(2rem, 10vw);
    margin-right: max(2rem, 10vw);
  }

  :is(form) {
    margin-top: 2rem;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    align-items: stretch;
    justify-content: center;
  }

  :is(input) {
    padding: 0.5rem 1rem;
    border: none;
    flex: 0 1 auto;
  }

  .button {
    padding: 0.75rem 2rem;
  }
}
