@supports (grid-template-rows: masonry) {
  .masonry {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: masonry;
  }

  .masonry .cell {
    /* Reset the effect of the cell size classes */
    grid-column-end: span 1 !important;
  }
}

@supports not (grid-template-rows: masonry) {
  .js .masonry {
    grid-auto-rows: 8px;
  }

  .masonry .cell {
    /* Set a minimal initial height */
    grid-row-end: span 8;
  }
}
