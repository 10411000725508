.services {
  margin-bottom: var(--p-xl);
}

.services__service {
  font-size: var(--fs-large);
  letter-spacing: -0.05em;
  overflow-wrap: break-word;
  hyphens: auto;
}

.services__service h2 {
  font-weight: normal;
}

.services__service:hover,
.services__service:focus-visible {
  background: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
