.form {
  :is(
      input[type="text"],
      input[type="email"],
      input[type="number"],
      textarea,
      select
    ) {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid;
    margin-bottom: 0.5rem;
    background: transparent;
    color: var(--fg);

    &:focus {
      box-shadow: 3px 2px 11px 0 var(--fg);
      outline: none;
    }
  }
  label {
    display: block;

    > p {
      margin-bottom: 0.5rem;
    }
    input {
      margin-bottom: 0.5rem;
    }

    &:has(input[type="checkbox"]) {
      display: flex;
      gap: 0.5rem;
      > p {
        order: 2;
      }
      input {
        order: 1;
      }
    }
  }
}
