.footer {
  background: var(--black);
  color: var(--white);
  padding: 2.5rem 1rem 1rem;
  text-align: center;
  font-weight: bold;
  position: relative;

  html[data-theme="dark"] & {
    border-top: var(--border);
  }
}

.footer__top {
  display: block;
  padding: 0 2rem;

  :is(svg) {
    display: block;
    font-size: 2rem;
    margin: 0 auto;
  }
}

.footer__badges {
  margin-top: 3rem;
  display: flex;
  gap: 1rem;
  align-items: stretch;
  justify-content: center;
  filter: grayscale(1);
}

.footer__badge {
  display: block;
  flex: 0 1 10rem;

  &.cys {
    filter: invert(1);
  }

  &.google {
    flex-basis: 8rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    :is(img) {
      height: 55%;
    }
  }
}
