.section-title {
  text-align: right;
  margin: 8rem 1rem 0;
  font-size: var(--fs-section);

  :is(a) {
    color: inherit;
    text-decoration: none;
  }
}

h2 {
  line-height: 1.12;
}
