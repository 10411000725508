.intro__background {
  display: grid;
  place-items: center;
  min-height: min(60vh, 40vw);
}

.intro__logo {
  width: 50%;
}

.intro__feinheit {
  padding: 3rem;

  :is(h2) {
    font-size: var(--fs-call);
    margin-bottom: 2rem;
  }

  @media (--media-md) {
    padding: 5rem;
  }
}

.intro__slider {
  overflow: hidden;
  position: relative;
  aspect-ratio: 2000 / 1050;
}

.intro__slide {
  position: absolute;
  inset: 0;

  display: block;
  background-size: cover;
  aspect-ratio: 2000 / 1050;

  transition: 0.75s ease-in-out;
  opacity: 0;

  &.current {
    opacity: 1;
    z-index: 1; /* Clicks and tooltips */
  }
}
