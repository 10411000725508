.filter {
  padding: 1rem;
}

.filter .button,
.pill {
  background: transparent;
  color: var(--fg);
  border: var(--border);
  padding: 0.5rem 0.75rem;
}

.filter[data-show="_"] .button,
.filter .filter__active,
.button.pill {
  background: var(--filters);
  color: var(--white);
  border-color: transparent;
}

.pills {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
}

.button.pill {
}

.pill:not(a) {
  cursor: unset;
}
