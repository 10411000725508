/*
This font software is the property of Commercial Type.

You may not modify the font software, use it on another website, or install it on a computer.

License information is available at http://commercialtype.com/eula
For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

Copyright (C) 2021 Schwartzco Inc.
License: 2112-LDISTB
*/

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-SemiboldItalic-Web.woff2") format("woff2"),
    url("Graphik-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Semibold-Web.woff2") format("woff2"),
    url("Graphik-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-RegularItalic-Web.woff2") format("woff2"),
    url("Graphik-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik Web";
  src: url("Graphik-Regular-Web.woff2") format("woff2"),
    url("Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
