.angles {
  position: relative;
  padding: 3rem 3rem 3.5rem;
}

.angles::before,
.angles::after {
  position: absolute;
  content: "";
  background: url("static/assets/angle.svg");
  width: 16px;
  height: 16px;

  html[data-theme="dark"] & {
    background: url("static/assets/angle-white.svg");
  }
}

.angles::before {
  top: 1rem;
  left: 1rem;
}

.angles::after {
  bottom: 1rem;
  right: 1rem;
  transform: rotate(180deg);
}
