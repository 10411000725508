.gallery__image {
  cursor: pointer;
}

.gallery__full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: var(--z-top);
  padding: 2rem;
  display: grid;
  place-items: center;

  & img {
    max-width: 100%;
    max-height: 100%;
    width: auto !important; /* Revert the bad CSS in project-detail.css */
  }
}

.gallery__controls {
  display: none;
  position: fixed;
  z-index: var(--z-top);
  top: 50%;

  .icon {
    --size: 3em;
    background: rgba(255 255 255 / 0.3);
    transition: background 0.2s;
  }

  &:hover .icon {
    background: rgba(255 255 255 / 0.5);
  }

  &.gallery__previous {
    left: 1rem;
    transform: rotate(-90deg);
  }
  &.gallery__next {
    right: 1rem;
    transform: rotate(90deg);
  }

  .gallery.is-active & {
    display: block;
  }
}
