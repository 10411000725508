.cta {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;
  padding: 0;
  border-bottom: var(--border);

  /* Hack the gap below the button in Chromium engines */
  position: relative;
  top: -1px;
}

.cta__backlink {
  margin-right: auto;
  padding: 1rem;

  :is(svg) {
    --size: 1.125em;
    vertical-align: text-bottom;
  }
}

.cta__button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  background: var(--gradient);
  animation: var(--hue-rotate);
  color: #fff;

  padding: 0.75rem 1.5rem;
  line-height: 1em;
}

.cta-no-border .cta {
  border: none;
}

@media (--media-sm-only) {
  .cta__button {
    position: fixed;
    top: 0;
    width: calc(var(--menu-width) - 1px);
    height: calc(var(--menu-width) - 1px);
    z-index: var(--z-menu);
    padding: 0;
  }
}
