.quotation {
  background: var(--gradient);
  color: var(--white);

  padding-top: 1.5rem;
}

.quotation__body {
  padding: 1.5rem;

  font-size: var(--fs-small);
  line-height: 1.2;
}

.quotation__image {
  margin-left: -1.5rem;
}

.quotation__quotation {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 1rem;
}

.quotation__name {
  font-weight: 600;
}

@media (--media-md) {
  .quotation {
    position: relative;
    margin-left: 3rem;
    margin-right: 0;
    padding-top: 1rem;
  }

  .quotation__image {
    position: absolute;
    margin: 0;
    left: -10%;
    width: 50%;
  }

  .quotation__body {
    padding-left: 45%;
    padding-bottom: 3rem;
  }

  .quotation__quotation {
    margin-bottom: 2rem;
  }
}

@media (--media-lg) {
  .quotation {
    margin-right: -2rem;
  }
}
