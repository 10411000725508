.details {
  height: var(--collapsed);
  overflow: hidden;
  transition: height 600ms cubic-bezier(0.19, 1, 0.22, 1), background 400ms;
}

.details[open] {
  height: var(--expanded);

  summary .toggle--more {
    display: none;
  }
  summary .toggle--less {
    display: flex;
  }
}

summary {
  list-style: none;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }

  .toggle--more,
  .toggle--less {
    font-weight: bold;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    pointer-events: none;

    .icon {
      --size: 1.33rem;
    }
  }

  .toggle--more {
    display: flex;
  }
  .toggle--less {
    display: none;
  }
}
