.project {
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    hyphens: auto;
    line-height: 1.2;
  }

  & .angles {
    & p {
      font-size: var(--fs-small);
      padding: 1em;

      @media (--media-md) {
        font-size: 1.25rem;
      }

      @media (--media-lg) {
        padding: 2rem;
      }
    }
  }

  p {
    font-size: 1.25rem;
  }
  .prose {
    margin-inline: unset;
  }
}

.project__title {
  font-size: var(--fs-section);
}

.project__lead-image {
  position: relative;

  :is(img) {
    width: 100%;
  }

  :is(.copyright) {
    position: absolute;
    right: 2rem;
    bottom: 2px;
    display: block;
  }
}

.project-detail-container {
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    align-self: flex-start;
    hyphens: auto;
  }

  & .flips__text {
    @media (--media-lg) {
      & h2 {
        margin-left: -1rem;
      }
    }
  }

  html[data-theme="dark"] & a {
    color: #8ca8de;
  }

  & .flow:not(.flips__text) {
    @media (--media-lg) {
      padding: var(--padding);
    }
  }

  .flips--consultant {
    margin-bottom: unset;

    /* to overlap the borders */
    @media (--media-md) {
      top: -1px;
    }
  }

  & img:not(.flips__part--image) {
    width: 100%;
  }

  & img + img {
    margin-top: var(--p);
  }
}

.project .text--meta:is(h2) {
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.project__details p {
  font-size: unset;
}
