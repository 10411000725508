.consultant {
  height: 10rem;
  display: flex;
  gap: 1rem;
}

.consultant__text {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
