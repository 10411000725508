.sidebar__menu .dark-mode {
  margin: -0.5rem 0;

  @media (--media-md) {
    transform: rotate(90deg);
  }

  :is(svg) {
    --size: 1.5em;
  }
}

html[data-theme="dark"] {
  #dark-mode-cycle {
    color: #fff;
  }
}
