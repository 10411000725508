:root {
  --black: #333;
  --white: #ffffff;

  --fg: var(--black);
  --bg: var(--white);

  --p: 1rem;
  --p-lg: 3rem;
  --p-xl: 6rem;

  --filters: #333;

  --error-fg: #ff0000;
  --error-bg: #ffd7d7;
  --warning: orange;

  --border: 1px solid var(--fg);

  --z-skiplinks: 6;
  --z-top: 5;
  --z-flips: 4;
  --z-menu: 3;

  --nice: #473c67;

  --gradient-darkgray: #333;
  --gradient: var(--nice);

  /* Font sizes */
  --fs-small: 0.875;
  --fs-large: 1.5rem;
  --fs-larger: 2rem;

  --fs-section: 3rem;
  --fs-call: 1.5rem;
}

html[data-theme="dark"] {
  --gradient-darkgray: #333;
  --filters: #111;
}

@media (--media-md) {
  :root {
    --fs-section: 4.5rem;
    --fs-call: 2rem;
  }
}

@media (--media-lg) {
  :root {
    --fs-section: 6rem;
    --fs-call: 3rem;
  }
}

html[data-theme="dark"] {
  --fg: var(--white);
  --bg: var(--black);
}

.darkgray {
  --gradient: var(--gradient-darkgray);
}
