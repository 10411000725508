:root {
  --flips-height: 8rem;
  --flips-gap: 2rem;
}

.flips {
  position: relative;
}

.grid--padded .flips {
  /* HACK FIXME */
  margin-left: -1rem;
  margin-right: -1rem;
}

.flips__part {
  color: var(--white);
}

.flips__part--image {
  object-fit: cover;
}

.flips__part--text,
.flips__part--demicircle {
  background: var(--gradient, var(--fg));

  :is(a, svg) {
    color: var(--white);
  }
}

.flips__dateline {
  display: block;
  font-weight: normal;
  font-size: 1rem;
}

@media (--flips-sm) {
  .flips__wrapping {
    padding: 1rem 0;
    min-height: 5rem;
  }

  .flips__part--text {
    display: none;
  }

  .flips__text {
    padding: 1rem 1rem 1rem 3rem;
    display: flex;
  }

  .flips--consultant {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: none;
    min-height: 8rem;

    .flips__text {
      flex-flow: column;
    }

    h2 {
      display: none;
    }

    p {
      display: block;
      margin-top: 0;
    }
  }
}

@media (--flips-lg) {
  .flips-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: var(--flips-gap);
  }

  .flips--consultant {
    position: relative;
    top: 1rem;

    h2,
    p {
      display: block;
    }
  }

  .flips--consultant:last-child {
    margin-bottom: calc(-1 * var(--tube));
    border-bottom: none;
  }

  .flips__wrapping {
    height: var(--flips-height);
    display: grid;
    grid-template-columns: 3fr 7fr;
    z-index: var(--z-flips);
  }

  .flips__part--text {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .flips__text {
    padding: 2rem;
    padding-left: 3rem;
    max-width: 24rem;

    :is(h2, p) {
      margin: 0.5rem 0;
      grid-column: 1;
    }

    :is(h2) {
      font-size: var(--fs-larger);
    }

    :is(svg) {
      --size: 1em;
    }
  }
}

.flips__contact {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  a {
    display: flex;
    gap: 4px;
    align-items: center;
    text-decoration: none;
  }
}

@media (min-width: 1100px) {
  .flips__text p {
    display: block;
  }
}
